import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios';


Vue.use(Vuex)

const showDialogInternal = function (dialog, {
    type,
    text,
    buttonText,
    time,
    callback,
    cancelButtonText,
    cancelButtonColor,
    cancelButtonCallback
}) {
    dialog.visible = true
    dialog.text = text
    dialog.type = type
    dialog.callback = callback
    dialog.buttonText = buttonText
    dialog.cancelButtonText = cancelButtonText
    dialog.cancelButtonColor = cancelButtonColor
    dialog.cancelButtonCallback = cancelButtonCallback
    if (dialog.timeout) {
        clearTimeout(dialog.timeout)
    }

    if (time && time > 0) {
        dialog.timeout = setTimeout(function () {
            dialog.visible = false
            dialog.text = ""
        }, time)
    }
}

const hideDialogInternal = function (dialog) {
    dialog.visible = false
    dialog.text = ""
}

const searchByEan = function (orderReturnLogs, ean) {
    let result = []
    for (const i in orderReturnLogs) {
        const orderReturnLog = orderReturnLogs[i]
        if (ean === orderReturnLog.ean)
        result.push(orderReturnLog)
    }
    return result
}


let store = new Vuex.Store({
    state: {
        loading: false,
        alertDialog: {
            visible: false,
            text: "",
            type: "",
            timeout: null
        },
        confirmDialog: {
            visible: false,
            text: "",
            buttonText: "",
            cancelButtonText: null,
            cancelButtonColor: null,
            type: "error",
            timeout: null,
            callback: null
        },
        returnDialog: {
            visible: false,
            text: "",
            buttonText: "",
            cancelButtonText: null,
            cancelButtonColor: null,
            type: "error",
            timeout: null,
            callback: null
        },
        order: {},
        currentArticleToReturn: null,
        articlesToReturn: []
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },
        setOrder(state, order) {
            state.order = order
        },
        showAlertDialog(state, {type, text, time}) {
            showDialogInternal(state.alertDialog, {type, text, time})
        },
        hideAlertDialog(state) {
            hideDialogInternal(state.alertDialog)
        },
        showConfirmDialog(state, {
            type,
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            showDialogInternal(state.confirmDialog, {
                type,
                text,
                buttonText,
                time,
                callback,
                cancelButtonText,
                cancelButtonColor,
                cancelButtonCallback
            })
        },
        hideConfirmDialog(state) {
            hideDialogInternal(state.confirmDialog)
        },
        showReturnDialog(state) {
            showDialogInternal(state.returnDialog, {})
        },
        hideReturnDialog(state) {
            hideDialogInternal(state.returnDialog)
        },
        setCurrentArticleToReturn(state, currentArticle) {
            state.currentArticleToReturn = currentArticle
        },
        addArticleToReturn(state, articleToReturn) {
            state.articlesToReturn.push(articleToReturn)
            state.currentArticleToReturn.item.returnedQuantity += 1
        },
        clearArticlesToReturn(state) {
            state.articlesToReturn = []
        },
        removeReturnArticle(state, index) {
            state.articlesToReturn.splice(index, 1)
        },
        setReturnStorage(state, storageLocation) {
            state.returnStorageLocation = storageLocation
        },
    },
    actions: {
        login({commit}, {username, password}) {
            commit('setLoading', true)
            const self = this;
            return new Promise((resolve, reject) => {
                const request = {
                    username: username,
                    password: password
                }

                axios
                    .post("/api/login", request)
                    .then(function (response) {
                        let authenticationResponse = response.data;
                        localStorage.setItem("lang.authentication.jwtToken", authenticationResponse.jwtToken)
                        resolve()
                    })
                    .catch(function (error) {
                        console.log(error)
                        self.dispatch('showError', {text: "Fehler beim Login", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false));
            })
        },
        showError({commit}, {text, time}) {
            const type = 'error'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showErrorConfirm({commit}, {
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            const type = 'error'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {
                type,
                text,
                buttonText,
                time,
                callback,
                cancelButtonText,
                cancelButtonColor,
                cancelButtonCallback
            })
        },
        showInfo({commit}, {text, time}) {
            const type = 'info'
            commit('hideAlertDialog')
            commit('showAlertDialog', {type, text, time})
        },
        showInfoConfirm({commit}, {
            text,
            buttonText,
            time,
            callback,
            cancelButtonText,
            cancelButtonColor,
            cancelButtonCallback
        }) {
            const type = 'info'
            commit('hideConfirmDialog')
            commit('showConfirmDialog', {
                type,
                text,
                buttonText,
                time,
                callback,
                cancelButtonText,
                cancelButtonColor,
                cancelButtonCallback
            })
        },
        showReturnDialog({commit}, currentArticle) {
            commit('setCurrentArticleToReturn', currentArticle)
            commit('hideReturnDialog')
            commit('showReturnDialog', {})
        },
        loadOrder({commit}, billBeeOrderId) {
            commit('setLoading', true)
            console.log("Loading order " + billBeeOrderId)
            const self = this
            return new Promise((resolve, reject) => {
                axios.get("/api/return/" + billBeeOrderId)
                    .then(function (response) {
                        const orderResponse = response.data
                        if (orderResponse.code === "Ok") {
                            const order = orderResponse.data

                            // patch dispatched quantity
                            for (const i in order.orderItems) {
                                let orderItem = order.orderItems[i];
                                const orderReturnLogs = searchByEan(order.returnedItems, orderItem.product.ean)
                                orderItem.returnedQuantity = orderReturnLogs.length
                                self.dispatch("loadDetails", orderItem)
                            }

                            commit('setOrder', order)
                            resolve(order)
                        } else if (orderResponse.code === "OrderNotFound") {
                            self.dispatch('showError', {text: "Order nicht gefunden", time: 3000})
                            reject()
                        } else if (orderResponse.code === "OrderNotReturnable") {
                            self.dispatch('showError', {text: "Retoure kann nicht erfasst werden.", time: 3000})
                            reject()
                        } else if (orderResponse.code === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function (error) {
                        if (process.env.VUE_APP_DEBUG_MODE) console.log(error)
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        loadDetails({commit}, orderItem) {
            commit('setLoading', false)
            const product = orderItem.product
            axios
                .get("/api/products/" + product.ean + "/lite")
                .then(function (res) {
                    const productLiteResponse = res.data
                    if (productLiteResponse !== null && productLiteResponse.code === "ProductFoundInBillbee") {
                        const productLite = productLiteResponse.data;
                        product.extraDataLoaded = true
                        product.images = productLite.images
                        product.color = productLite.color
                        product.size = productLite.size
                        product.stock = productLite.stock
                        product.category1 = productLite.category1
                        product.returnIndication = productLite.returnIndication
                    }
                })
        },
        createLabelAndPrint({commit}, {ean, quantity}) {
            commit('setLoading', true)
            const self = this
            const url = "/api/labels/createLabelAndPrint?ean=" + ean + "&quantity=" + quantity
            return new Promise((resolve, reject) => {
                axios.post(url)
                    .then(function (response) {
                        const createLabelResponse = response.data
                        if (createLabelResponse.code === "Ok") {
                            self.dispatch('showInfo', {text: "Die Labels wurden erfolgreich erstellt", time: 1000})
                            resolve()
                        } else if (createLabelResponse.code === "ProductNotFound") {
                            self.dispatch('showError', {text: "Das Produkt konnte nicht gefunden werden", time: 3000})
                            reject()
                        } else if (createLabelResponse.code === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        returnOrder({commit, state}) {
            commit('setLoading', true)
            const self = this
            let orderReturnRequest = {
                order: state.order,
                itemsToReturn: state.articlesToReturn
            }
            return new Promise((resolve, reject) => {
                axios.post("/api/return/returnOrder", orderReturnRequest)
                    .then(function (response) {
                        const returnOrderResponse = response.data
                        if (returnOrderResponse.code === "Ok") {
                            self.dispatch('showInfo', {text: "Retoure erfolgreich gebucht", time: 1000})
                            resolve()
                            commit('clearArticlesToReturn')
                        } else if (returnOrderResponse.code === "TechnicalError") {
                            self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                            reject()
                        }
                    })
                    .catch(function () {
                        self.dispatch('showError', {text: "Ein technischer Fehler ist aufgetreten", time: 3000})
                        reject()
                    })
                    .finally(() => {
                        commit('setLoading', false)
                    })
            })
        },
    },
    modules: {}
});
export default store

export const useStore = () => store